import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import "./Styles.css";
import Sticky from "@ui/sticky";

const OneLife = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);


    return (

        <Layout pageTitle="" className="white-version">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />

        <div>
            <div>
                <main className="main-page-wrapper">
                    <div className="rn-slider-area">
                        <div className="container">
                            <div className="row row--30 pt--100 pt_sm--50">
                                <div className="col-lg-6">
                                    <div className="d-flex flex-wrap align-content-start h-100">
                                        <Sticky
                                            className="sticky-top-slider"
                                            top="200px"
                                        >
                                            <div className="banner-details-wrapper-sticky slide">
                                                <div className="thumbnail">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                                <div className="banner-title-area pt--30">
                                                    <h1
                                                        className="title"
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        Hi, I’m{" "}
                                                        <span>Anuj</span>
                                                        <br />
                                                        {/*<span className="span" style={{ color: '#000' }}> Software Engineer.</span>*/}
                                                    </h1>
                                                    <p className="disc">
                                                        “One Life” is a mantra I
                                                        live by. I believe all
                                                        we have is this one life
                                                        and the same 24 hours
                                                        every day – not a second
                                                        more or less. I go
                                                        through this one life,
                                                        satiating my hunger for
                                                        knowledge and
                                                        experiences that enrich
                                                        my life, work, and time
                                                        on earth. 
                                                    </p>
                                                </div>
                                            </div>
                                        </Sticky>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    One Life
                                                </h5>
                                                <p className="about-disc">
                                                    I aim to begin each sunrise
                                                    with gratitude and maximise
                                                    the day that unfolds. I
                                                    pursue new experiences,
                                                    learnings and adventures.
                                                    Travelling the world,
                                                    backpacking through new
                                                    lands, relishing cuisines,
                                                    meeting people from various
                                                    walks of life, diving from
                                                    the skies, or deep into blue
                                                    waters, I go through this
                                                    one life trying to live it
                                                    to the fullest.
                                                </p>
                                                <p className="about-disc">
                                                    Content writer, scuba
                                                    driver, traveller, foodie,
                                                    tech-head, banking
                                                    executives, farmer…one life
                                                    is all we have.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <Footer data={{ ...data.footer }} className="section-separator" />
        </Layout>
    );
};




export const query = graphql`
    query OneLife {
        site {
            ...Site
        }
        header: general(section: { eq: "header-1-white" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-1-white" }) {
            ...Footer01
        }
        homePage(title: { eq: "politician-home-white" }) {
            content {
                ...Content01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

OneLife.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({
                    phone: PropTypes.string,
                    email: PropTypes.string,
                }),
                getform_url: PropTypes.string,
            }),
        }),
     
        
        navigation: PropTypes.shape({}),
        header: PropTypes.shape({}),
        footer: PropTypes.shape({}),
    }),
};

export default OneLife;
